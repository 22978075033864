<template>
    <div class="row-block">
        <ul class="columns-block">
            <li class="column">
                <div class="column-block">
                    <PuSkeleton class="skeleton-input" style="width: 60px"></PuSkeleton>
                    <div class="bottom-part order-status">
                        <div class="bottom-block right-border">
                            <PuSkeleton width="60px" class="skeleton-input"></PuSkeleton>
                        </div>
                    </div>
                </div>
            </li>
            <li class="column">
                <div class="column-block">
                        <span class="order-number">
                            <PuSkeleton width="60px" class="skeleton-input"></PuSkeleton>
                        </span>
                        <span class="order-number">
                            <PuSkeleton width="60px" class="skeleton-input"></PuSkeleton>
                        </span>
                        <span class="order-number">
                            <PuSkeleton width="60px" class="skeleton-input"></PuSkeleton>
                        </span>
                    <div class="bottom-part order-user">
                        <div class="bottom-block right-border">
                            <PuSkeleton width="60px" class="skeleton-input"></PuSkeleton>
                            <div class="user-name"
                                 data-toggle="tooltip">
                                <PuSkeleton width="60px" class="skeleton-input"></PuSkeleton>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="column">
                <div class="column-block">
                    <div>
                        <div class="date-block">
                            <PuSkeleton width="60px" class="skeleton-input"></PuSkeleton>
                        </div>
                        <div class="date-block">
                            <PuSkeleton width="60px" class="skeleton-input"></PuSkeleton>
                        </div>
                    </div>
                </div>
            </li>
            <li class="column">
                <div class="column-block">
                    <div>
                        <div class="price-block">
                            <div class="text">
                                <PuSkeleton width="60px" class="skeleton-input"></PuSkeleton>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-part">
                        <div class="bottom-block">
                            <span class="option image">
                                <PuSkeleton width="126px" class="skeleton-input"></PuSkeleton>
                            </span>
                        </div>
                    </div>
                </div>
            </li>
            <li class="column">
                <div class="column-block">
                    <div>
                        <div class="price-block">
                            <div class="text">
                                <PuSkeleton width="60px" class="skeleton-input"></PuSkeleton>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-part">
                        <div class="bottom-block">
                            <span class="option image">
                                <PuSkeleton width="60px" class="skeleton-input"></PuSkeleton>
                            </span>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'RowBlockSkeleton',
}
</script>
