<script>
import receipt from "@/views/penalties/mixins/receipt";

export default {
    name: "PenaltyPopover",
    props: ['penalty'],
    mixins: [receipt]
}
</script>

<template>
    <div>
        <el-popover
            placement="right-start"
            width="200"
            trigger="click"
        >
            <div class="cursor-pointer">
                <el-row type="flex" justify="center" align="bottom">
                    <el-col :span="3">
                        <i class="icon eye-black"></i>
                    </el-col>
                    <el-col>
                        <router-link tag="span" :to="{name:'show-penalty', params: {id: penalty.id}}"
                                     class="cursor-pointer underline">
                            Детальна інформація
                        </router-link>
                    </el-col>
                </el-row>
                <div v-if="penalty.status === 'ОЧІКУЄТЬСЯ ПІДТВЕРДЖЕННЯ ОПЛАТИ З ДЕРЖАВНОЇ КАЗНАЧЕЙСЬКОЇ СЛУЖБИ' || penalty.dpaid" @click="getReceipt(penalty.id)" class="cursor-pointer pt-2">
                    <el-row type="flex" justify="center" align="bottom">
                        <el-col :span="3">
                            <i class="icon download"></i>
                        </el-col>
                        <el-col :span="21">
                            <span class="underline">Завантажити квитанцію</span>
                        </el-col>
                    </el-row>
                </div>
                <div v-else>
                    <div @click="$bvModal.show(`modal-pay-${penalty.id}`)" class="cursor-pointer pt-2 pb-2">
                        <el-row type="flex" justify="center" align="bottom">
                            <el-col :span="3">
                                <i class="icon coin-black"></i>
                            </el-col>
                            <el-col :span="21">
                                <span class="underline">Оплата штрафу</span>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="cursor-pointer pb-2">
                        <el-row type="flex" justify="center" align="bottom">
                            <el-col :span="3">
                                <i class="icon contact-black"></i>
                            </el-col>
                            <el-col :span="21">
<!--                                client_info-->
                                <router-link tag="span" :to="{name:'client_info', params: {clientId: penalty.insurer.order.clients_id}}"
                                             class="cursor-pointer underline">
                                    Переглянути контакти
                                </router-link>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
            <div slot="reference"><i class="icon horizontal-3dots cursor-pointer"></i></div>
        </el-popover>
    </div>
</template>

<style scoped>
.underline:hover {
    text-decoration: underline;
}
</style>
