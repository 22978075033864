<script>

export default {
    name: "PenaltyStatistic",
}
</script>

<template>
    <div class="mn__in">
        <div class="mn__in__label" data-toggle="collapse" data-target="#mn1" aria-expanded="false"
             aria-controls="mn1" @click.stop style="background-color: #999DA6">
            <span>Статистика</span>
            <svg width="22" height="12" viewBox="0 0 22 12" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0)">
                    <path
                        d="M1.441 8.98662e-07L-4.63781e-07 1.38992L11 12L22 1.38992L20.559 6.29881e-08L10.9945 9.22546L1.441 8.98662e-07Z"
                        fill="white"/>
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="12" height="22" fill="white"
                              transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 22 12)"/>
                    </clipPath>
                </defs>
            </svg>
        </div>
        <div id="mn1" class="mn__in__body mn__in__body-np collapse">
            <div class="mn__table__wrapper">
                <div class="mn__table">
                    <div class="mn__table__row mn__table__row-head">
                        <div class="mn__table__cell">test</div>
                        <div class="mn__table__cell">test</div>
                    </div>
                    <div class="mn__table__row mn__table__row-ps">
                        <span class="mn__table__row-ps__item">Кількість авто на моніторингу:</span>
                        <span class="mn__table__row-ps__item">12312</span>
                    </div>
                    <div class="mn__table__row mn__table__row-ps">
                        <span class="mn__table__row-ps__item">Сплачених штрафів вашими клієнтами:</span>
                        <span class="mn__table__row-ps__item">12312</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
