<script>
import penaltyApi from '@/api/penalty'
import Vue from "vue";
import {downloadExcel} from "@/api/client";
import FileDownload from "js-file-download";

export default {
    name: "ExcelExport",
    methods: {
        exportPenalties() {
            penaltyApi.exportPenalties(this.$store.state.penaltyStore.filters).then(response => {
                // console.log(response.data)
                if (response?.data) {
                    FileDownload(response.data, 'report.xlsx');
                }
            })
            // const link = document.createElement('a');
            // link.href = '/api/penalties/export'
            // link.target = '_blank';
            // document.body.appendChild(link);
            // link.click();
            // document.body.removeChild(link);
        }
    }
}
</script>

<template>
    <div class="insurance-history__menu__filters">
        <button @click="exportPenalties">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="3" fill="#207245"/>
                <path d="M6.16667 4.5C5.24167 4.5 4.5 5.24167 4.5 6.16667V17.8333C4.5 18.7583 5.24167 19.5 6.16667 19.5H17.8333C18.7583 19.5 19.5 18.7583 19.5 17.8333V6.16667C19.5 5.24167 18.7583 4.5 17.8333 4.5H6.16667ZM6.16667 6.16667H17.8333V17.8333H6.16667V6.16667ZM12.8333 12L15.5 16.1667H13.8333L12 13L10.1667 16.1667H8.5L11.1667 12L8.5 7.83333H10.1667L12 11L13.8333 7.83333H15.5L12.8333 12Z" fill="white"/>
            </svg>
            Експорт в Excel
        </button>
    </div>
</template>

<style scoped>

</style>
