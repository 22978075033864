<script>
import CheckPenaltiesButton from "@/components/app/penalties/CheckPenaltiesButton.vue";
import FilterBlock from "@/views/penalties/components/FilterBlock.vue";
import PenaltyStatistic from "@/views/penalties/components/PenaltyStatistic.vue";
import RowBlockSkeleton from "@/views/penalties/components/RowBlockSkeleton.vue";
import Paginate from '@/components/Pagination/index.vue';
import {mapActions, mapState} from "vuex";
import ModalPay from "@/views/penalties/components/ModalPay.vue";
import PenaltyPopover from "@/views/penalties/components/PenaltyPopover.vue";
import penaltyApi from '@/api/penalty'
import moment from 'moment'
import displaySettings from '@/mixin/displaySettings';

export default {
    name: "index",
    mixins: [displaySettings],
    components: {
        PenaltyPopover,
        ModalPay,
        RowBlockSkeleton,
        PenaltyStatistic,
        FilterBlock,
        CheckPenaltiesButton,
        Paginate
    },
    data(){
      return {
          isLoadData: true,
          page: 1,
          paid: null,
          countPaid: 0,
          moment: moment,
          limit: 10,
      }
    },
    computed:{
        ...mapState('penaltyStore', [
            'total',
            'penalties'
        ]),
    },
    methods: {
        ...mapActions('penaltyStore', [
            'getPenalties'
        ]),
        checkDiscount(penalty) {
            return parseFloat(penalty.sumPenalty) < parseFloat(penalty.sumFine) && Math.abs(moment(penalty.ddecision).diff(moment(), 'days')) < 10;
        },
        countPenalty() {
            penaltyApi.countPenalty({paid: 0}).then(response => {
                this.countPaid = response.data
            })
        },
        async changeTab(tab) {
            this.isLoadData = true
            await this.getPenalties({
                page: this.page,
                paid: tab === null ? null : tab ? 1 :0
            })
            this.paid = tab
            this.isLoadData = false
        },
        async changeCurrenPage(data) {
            this.isLoadData = true
            await this.getPenalties({
                page: data.page,
                limit: data.limit,
                paid: this.paid === null ? null : this.paid ? 1 :0
            })
            this.page = data.page
            this.isLoadData = false
        },
        async updatePenalties() {
            await this.getPenalties({
                page: this.page,
                paid: this.paid === null ? null : this.paid ? 1 :0
            })
            this.isLoadData = false
        }
    },
    async mounted() {
        this.countPenalty();
        await this.getPenalties({
            page: this.page
        })
        this.isLoadData = false
    }
}
</script>

<template>
    <div class="penalties">
        <div class="row">
            <div class="col-4">
<!--                //TODO soon -->
<!--                <span class="soon">Скоро</span>-->
<!--                <CheckPenaltiesButton></CheckPenaltiesButton>-->
<!--                <div class="row pt-2 pb-2">-->
<!--                    <div class="col-6">-->
<!--                        <span class="soon mb-4" style="bottom: 4px">Скоро</span>-->
<!--                        <div class="mt-3">-->
<!--&lt;!&ndash;                            <FilterBlock></FilterBlock>&ndash;&gt;-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="col-6">-->
<!--                        <span class="soon mb-4" style="bottom: 4px">Скоро</span>-->
<!--                        <div class="mt-3">-->
<!--                            <ExcelExport></ExcelExport>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
            <div class="col-8">
<!--                //TODO soon -->
<!--                <span class="soon">Скоро</span>-->
<!--                <PenaltyStatistic></PenaltyStatistic>-->
            </div>
        </div>
        <el-row>
            <FilterBlock></FilterBlock>
        </el-row>
        <div class="row">
            <div class="list-orders container">
                <div v-if="isMobile" class="form-group">
                    <el-select
                        class="form-control-el"
                        v-model="paid"
                        @change="changeTab"
                    >
                        <el-option
                            :value="null"
                            label="Всі"
                        />
                        <el-option
                            :value="false"
                            label="Очікують сплати"
                        />
                        <el-option
                            :value="true"
                            label="Сплачені"
                        />
                    </el-select>
                </div>
                <div v-else class="tabs-block">
                    <ul class="status-tabs">
                        <li class="tab">
                            <a :class="paid === null ? 'active cursor-pointer' : 'cursor-pointer'"
                               @click="changeTab(null)"
                            >Всі</a>
                        </li>
                        <li class="tab">
                            <a :class="paid === false ? 'active cursor-pointer' : 'cursor-pointer'"
                                @click="changeTab(false)"
                               class="d-block"
                            >
                                <div class="d-flex align-items-center">
                                    <div class="mr-1">Очікують сплати</div>
                                    <div class="count-no-paid pr-2 pl-2">
                                        <div>{{ countPaid }}</div>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li class="tab">
                            <a :class="paid === true ? 'active cursor-pointer' : 'cursor-pointer'"
                               @click="changeTab(true)"
                            >Сплачені</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
<!--        v-else-if="! isMobile"-->
        <div v-if="isMobile">
            <div v-for="penalty in penalties" class="mb-3 penalty">
                <el-row type="flex" class="mb-2">
                    <el-col>
                        <div>
                            <b>Номер постанови</b>
                        </div>
                        <div>{{ penalty.sprotocol + penalty.nprotocol }}</div>
                    </el-col>
                    <el-col>
                        <div>
                            <b>Дата правопорушення</b>
                        </div>
                        <div>
                            {{ moment(penalty.dperpetration).format("YYYY-MM-DD HH:mm") }}
                        </div>
                    </el-col>
                </el-row>
                <el-row v-if="penalty.insurer" type="flex" class="mb-3">
                    <el-col>
                        <div>
                            <b>ПІБ</b>
                        </div>
                        <div>
                            <span>{{ penalty.insurer.surname }}</span><br>
                            <span>{{ penalty.insurer.name }}</span><br>
                            <span>{{ penalty.insurer.patronymic }}</span>
                        </div>
                    </el-col>
                    <el-col>
                        <div>
                            <b>Транспортний засіб</b>
                        </div>
                        <div>
                            <span class="text">{{ penalty.licensePlate }}</span><br>
                            <span class="value">{{ penalty.brand }}</span><br>
                            <span class="value">{{ penalty.mark }}</span><br>
                        </div>
                    </el-col>
                </el-row>
                <el-row type="flex" class="mb-2">
                    <el-col>
                        <div><b>Сума, грн</b></div>
                        <div>
                            <span>{{ penalty.sumPenalty }}</span><br>
                            <span><s>{{ penalty.sumFine }}</s></span>
                        </div>
                    </el-col>
                    <el-col>
                        <div><br></div>
                        <div>
                            <i class="icon eye"></i>
                            <router-link tag="span" :to="{name:'show-penalty', params: {id: penalty.id}}" class="cursor-pointer underline">
                                Детальніше
                            </router-link>
                        </div>
                    </el-col>
                </el-row>
                <el-row type="flex" class="mb-2">
                    <el-col>
                        <div class="d-flex">
                            <span class="label">Статус:</span>
                            <div class="status paid pr-1 pl-1" v-if="penalty.dpaid">
                                                <span>
                                                    Сплачено
                                                </span>
                            </div>
                            <div class="status wait-confirm pr-1 pl-1" v-else-if="penalty.status === 'ОЧІКУЄТЬСЯ ПІДТВЕРДЖЕННЯ ОПЛАТИ З ДЕРЖАВНОЇ КАЗНАЧЕЙСЬКОЇ СЛУЖБИ' && !penalty.dpaid">
                                                <span>
                                                    Очікує підтвердження
                                                </span>
                            </div>
                            <div class="status privilege pr-1 pl-1" v-else-if="checkDiscount(penalty)">
                                                <span>
                                                    Пільга на сплату
                                                    <i class="icon exclamation-circle"></i>
                                                </span>
                            </div>
                            <div class="status pr-1 pl-1" v-else>
                                                <span>
                                                    Пільга відсутня
                                                    <i class="icon exclamation-circle"></i>
                                                </span>
                            </div>
                        </div>
                    </el-col>
                    <el-col>
                        <div v-if="!penalty.dpaid" class="d-flex mr-2 cursor-pointer underline" @click="$bvModal.show(`modal-pay-${penalty.id}`)">
                            <i class="icon coin mr-1"></i>
                            <span>Сплатити</span>
                        </div>
                        <modal-pay :penalty="penalty" @update-penalties="updatePenalties"></modal-pay>
                    </el-col>
                </el-row>
                <el-row type="flex" class="mb-2">
                    <el-col>
                        <div v-if="penalty.insurer" class="d-flex">
                            <span class="label mr-1">Точка продажу:</span>
                            <div class="user-name mr-2"
                                 data-toggle="tooltip" data-placement="bottom"
                                 title="test">
                                {{ penalty.insurer.order.user.user_info.full_name }}
                            </div>
                        </div>
                    </el-col>
                    <el-col>
                        <penalty-popover :penalty="penalty"></penalty-popover>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div v-else class="list-orders">
            <div class="container-wrapp">
                <div class="header-block">
                    <ul class="columns-block">
                        <li class="column">Номер постанови</li>
                        <li class="column">ПІБ</li>
                        <li class="column">Транспортний засіб</li>
                        <li class="column">Дата правопорушення</li>
                        <li class="column">Сума, грн</li>
                    </ul>
                </div>
                <div v-if="isLoadData">
                    <row-block-skeleton v-for="index in 4" :key="index"></row-block-skeleton>
                </div>
                <div v-else>
                    <div class="row-block" v-for="penalty in penalties">
                        <ul class="columns-block">
                            <li class="column">
                                <div class="column-block">
                                    <span>{{ penalty.sprotocol }}{{ penalty.nprotocol }}</span>
                                    <div class="bottom-part order-status">
                                        <div class="bottom-block right-border">
                                            <span>Статус:</span>
                                            <div class="status paid" v-if="penalty.dpaid">
                                                <span>
                                                    Сплачено
                                                </span>
                                            </div>
                                            <div class="status wait-confirm" v-else-if="penalty.status === 'ОЧІКУЄТЬСЯ ПІДТВЕРДЖЕННЯ ОПЛАТИ З ДЕРЖАВНОЇ КАЗНАЧЕЙСЬКОЇ СЛУЖБИ' && !penalty.dpaid">
                                                <span>
                                                    Очікує підтвердження
                                                </span>
                                            </div>
                                            <div class="status privilege" v-else-if="checkDiscount(penalty)">
                                                <span>
                                                    Пільга на сплату
                                                    <i class="icon exclamation-circle"></i>
                                                </span>
                                            </div>
                                            <div class="status" v-else>
                                                <span>
                                                    Пільга відсутня
                                                    <i class="icon exclamation-circle"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="column">
                                <div class="column-block" v-if="penalty.insurer">
                                    <span>{{ penalty.insurer.surname }}</span>
                                    <span>{{ penalty.insurer.name }}</span>
                                    <span>{{ penalty.insurer.patronymic }}</span>
                                    <div class="bottom-part order-user">
                                        <div class="bottom-block right-border">
                                            <span>Точка продажу:</span>
                                            <div class="user-name"
                                                 data-toggle="tooltip" data-placement="bottom"
                                                 title="test">
                                                {{ penalty.insurer.order.user.user_info.full_name }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="column">
                                <div class="column-block">
                                    <span class="text">{{ penalty.licensePlate }}</span>
                                    <span class="value">{{ penalty.brand }}</span>
                                    <span class="value">{{ penalty.mark }}</span>
                                    <div class="bottom-part">
                                        <div class="bottom-block right-border">
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="column">
                                <div class="column-block">
                                    <div class="bottom-part">
                                        <div>{{ moment(penalty.dperpetration).format("YYYY-MM-DD HH:mm") }}</div>
                                    </div>
                                    <div class="column-block">
                                        <div class="bottom-part">
                                            <div class="bottom-block mr-3">
                                                <div class="d-flex mr-2">
                                                    <i class="icon eye"></i>
                                                    <router-link tag="span" :to="{name:'show-penalty', params: {id: penalty.id}}" class="cursor-pointer underline">
                                                        Детальніше
                                                    </router-link>
                                                </div>
                                                <div v-if="!penalty.dpaid" class="d-flex mr-2 cursor-pointer underline" @click="$bvModal.show(`modal-pay-${penalty.id}`)">
                                                    <i class="icon coin"></i>
                                                    <span>
                                                        Сплатити
                                                    </span>
                                                </div>
                                                <modal-pay :penalty="penalty" @update-penalties="updatePenalties"></modal-pay>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="column">
                                <div class="column-block">
                                    <div>
                                        <div v-if="checkDiscount(penalty)" class="column-block">
                                            <span>{{ penalty.sumPenalty }}</span>
                                            <span><s>{{ penalty.sumFine }}</s></span>
                                        </div>
                                        <div v-else>
                                            <span>{{ penalty.sumFine }}</span>
                                        </div>
                                    </div>
                                    <div class="bottom-part">
                                        <penalty-popover :penalty="penalty"></penalty-popover>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <Paginate :total="total" :page="page" :limit.sync="limit" @pagination="changeCurrenPage"></Paginate>
    </div>
</template>

<style scoped>
    .soon {
        position: absolute;
        z-index: 1;
        border-radius: 3px;
        border: 1px solid #EF4137;
        background: #FFFFFF;
        color: #EF4137;
        font-size: 10px;
        font-weight: 800;
        text-transform: uppercase;
        padding: 0 3px;
        display: inline-block;
        height: 15px;
    }
    .penalties {
        @media (max-width: 768px) {
            .penalty {
                font-size: 15px;
                border: 1px solid #DDE2E5;
                border-left: none;
                border-right: none;
                .status {
                    height: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 30px;
                    margin-left: 5px;
                    font-size: 12px;
                    color: white
                }
                .label {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20.64px;
                    text-align: left;
                    color: #999DA6;
                }
            }
        }
        .privilege {
            background: #FFAD3D !important;
        }
        .wait-confirm {
            background: #007BBB !important;
        }
        .status {
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 30px;
            margin-left: 5px;
            background: #34393D;
        }
        .paid {
            background: #02A94E !important;
        }
        .count-no-paid {
            background: #FFAD3D;
            color: #ffffff;
            border-radius: 20px;
            font-size: 10px;
            line-height: 1.5;
        }
        .column-block {
            color: #24282C;
            s {
                color: #999DA6;
            }
        }
        .underline:hover {
            text-decoration: underline;
        }
    }
</style>
