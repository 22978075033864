<script>
import {mapActions} from "vuex";
import ExcelExport from "@/views/penalties/components/ExcelExport.vue";

export default {
    name: "FilterBlock",
    components: {ExcelExport},
    data() {
        return {
            listQuery: {
                fullName: null,
                protocol: null,
                licensePlate: null,
                dperpetration: null,
                status: null,
                partner: null,
            },
            isShowFilter:false,
            statuses: [
                {
                    label: 'Сплачено',
                    value: 'paid',
                },
                {
                    label: 'Очікує підтвердження',
                    value: 'wait-confirm'
                },
                {
                    label: 'Пільга',
                    value: 'privileges',
                },
                {
                    label: 'Без пільги',
                    value: 'no-privileges',
                },
            ],
        }
    },
    computed : {
        partnersList() {
            return this.$store.getters.ALL_PARTNERS;
        },
    },
    methods: {
        ...mapActions('penaltyStore', [
            'getPenalties'
        ]),
        getAllPartners() {
            return this.$store.dispatch('GET_ALL_PARTNERS');
        },
        setFilters() {
            this.$store.commit('penaltyStore/SET_FILTERS', this.listQuery)
            this.getPenalties();
        },
        clearFilter(){
            this.listQuery = {
                fullName: null,
                protocol: null,
                licensePlate: null,
                dperpetration: null,
                status: null,
                partner: null,
            }
            this.$store.commit('penaltyStore/SET_FILTERS', this.listQuery)
        },
        showFilter() {
            this.isShowFilter = !this.isShowFilter
            if (this.isShowFilter === false) {
                this.clearFilter()
                this.getPenalties()
            } else {
                this.getAllPartners()
            }
        }
    }
}
</script>

<template>
    <div class="mt-3 mb-3">
        <div class="col-lg-3 col-md-12 pr-0 pl-0">
            <el-row type="flex">
                <el-col>
                    <div class="insurance-history__menu__filters">
                        <button style="width: 120px;text-align: left" @click="showFilter">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect y="4" width="24" height="2" fill="#24282C"/>
                                <rect y="18" width="24" height="2" fill="#24282C"/>
                                <circle cx="8" cy="5" r="4" fill="white" stroke="#24282C" stroke-width="2"/>
                                <circle cx="16" cy="19" r="4" fill="white" stroke="#24282C" stroke-width="2"/>
                            </svg>
                            {{ isShowFilter ? 'Приховати' : 'Фільтри'}}
                        </button>
                    </div>
                </el-col>
                <el-col>
                    <ExcelExport></ExcelExport>
                </el-col>
            </el-row>
        </div>
        <transition name="fade" class="mt-3">
            <div v-if="isShowFilter">
                <el-row type="flex" :gutter="40">
                    <el-col>
                        <div class="filter-title">Номер постанови</div>
                        <div>
                            <input class="form-control" v-model="listQuery.protocol"/>
                        </div>
                    </el-col>
                    <el-col>
                        <div class="filter-title">ПІБ</div>
                        <div>
                            <input class="form-control" v-model="listQuery.fullName"/>
                        </div>
                    </el-col>
                    <el-col>
                        <div class="filter-title">Номер авто</div>
                        <div>
                            <input class="form-control" v-model="listQuery.licensePlate"/>
                        </div>
                    </el-col>
                </el-row>
                <el-row type="flex" :gutter="40">
                    <el-col>
                        <div class="filter-title">
                            Статус
                        </div>
                        <el-select v-model="listQuery.status" placeholder="Виберіть статус" class="w-100">
                            <el-option
                                v-for="item in statuses"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col>
                        <div class="filter-title">
                            Точка продажу
                        </div>
                        <el-select v-model="listQuery.partner" placeholder="Виберіть точку продажу" class="w-100">
                            <el-option
                                v-for="item in partnersList"
                                :key="item.value"
                                :label="item.fullName"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-col>
                    <el-col>
                        <div class="filter-title">
                            Дата правопорушення
                        </div>
                        <el-date-picker
                            class="form-control-el w-100 date-block"
                            v-model="listQuery.dperpetration"
                            type="date"
                            format="dd-MM-yyyy"
                            value-format="yyyy-MM-dd"
                        />
                    </el-col>
                </el-row>
                <el-button type="primary" class="mt-3" @click="setFilters">Застосувати</el-button>
            </div>
        </transition>
    </div>
</template>

<style scoped>
.date-block {
    .el-input__prefix {
        text-align: right;
        right: 0;
        left: unset;
    }
    .el-input__suffix {
        .el-input__suffix-inner {
            .el-icon-circle-close {
                padding-right: 30px;
                padding-top: 12px;
            }
        }
    }
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
}
</style>
